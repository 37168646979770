﻿<template>
    <li v-bind="itemAttrs" ref="listItem">
        <component
            :is="as"
            class="dropdown-item d-flex flex-row justify-content-between"
            :class="[itemClass, busyIcon?'busy':undefined]"
            v-bind="$attrs"
            :href="as==='a' ? href : undefined"
            :disabled="disabled || undefined"
            :type="$attrs.type || (as==='button' ? 'button' : undefined)"
            @click="onClick">
            
            <span class="pe-5" :class="textContainerClass">
                <slot name="icon" v-if="$slots.icon" />
                <i class="fa-fw me-3" :class="busyIcon" v-else-if="icon && busyIcon" />
                <i class="fa-fw me-3" :class="icon" v-else-if="icon" />
                
                <span :class="textClass" v-text="ensureTextTranslated(text)" />
            </span>

            <slot name="end">
                <span v-if="$slots.default">
                    <i class="fa-fw far fa-angle-right" />
                </span>
                <span class="text-muted" style="opacity: .7" v-text="shortcut" v-else-if="shortcut" />
                <span v-else-if="endIcon">
                    <i class="fa-fw" :class="endIcon" />
                </span>
                <span :class="endTextClass" v-text="ensureTextTranslated(endText)" v-else-if="endText" />
            </slot>

        </component>

        <div class="d-flex flex-row px-3 small show fade" v-if="confirming">
            <i class="fa-fw me-3 fad" />
            <button class="dropdown-item" :disabled="!!busyIcon" @click="onConfirm">
                <i class="far fa-fw fa-check pe-1" />
                <span v-text="t('Aunoa.Command.Confirm')" />
            </button>
            <button class="dropdown-item" :disabled="!!busyIcon" @click="onAbort">
                <i class="far fa-fw fa-times pe-1" />
                <span v-text="t('Aunoa.Command.Abort')" />
            </button>
        </div>

        <ul class="dropdown-menu submenu fade-in" v-if="$slots.default">
            <slot />
        </ul>
    </li>
</template>

<script lang="ts">

import type {PromisableEvent} from "../../types";

import {useVisibilityEvents} from "../../utils/useVisibilityEvents";
import {computed, defineComponent, toRefs, ref} from "vue";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useIconAlias} from "../../utils/useIconAlias";
import {useBusy} from '../../utils/useBusy';
import {createPromisableEvent} from "../../utils/promisableEvents";

export default defineComponent({
    name: "AunoaDropdownItem",
    inheritAttrs: false,
    props: {
        as: {
            type: String,
            default: "button"
        },
        icon: {},
        text: {
            type: String,
            required: true
        },
        itemClass: {},
        textClass: {},
        textContainerClass: {},
        endIcon: {},
        endText: {
            type: String
        },
        endTextClass: {},
        shortcut: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            default: "#"
        },
        mustConfirm: {
            type: Boolean,
            default: false
        }
    },
    emits: ["click"],
    setup(props, {emit, slots}) {

        const {t, ensureTextTranslated} = useAunoaI18n();
        const confirming = ref(false);
        const {busyIcon, emitPromisableEvent} = useBusy();
        const {icon: iconProp, text} = toRefs(props);
        const {icon} = useIconAlias(iconProp);

        const as = computed(() => props.href !== "#" ? "a" : props.as);

        const itemAttrs = computed(() => ({
            ...slots.default ? ({class: "dropdown"}) : undefined
        }));

        const listItem = ref<HTMLLIElement>();
        if (props.mustConfirm) {
            const dropdownNavItem = computed(() => listItem.value?.parentElement?.parentElement as HTMLElement);
            useVisibilityEvents("dropdown", {
                element: dropdownNavItem,
                onShow: () => confirming.value = false
            });
        }

        const tryEmitClick = (event: PromisableEvent) => {
            if (as.value === "button") {
                event.stopPropagation();
                emitPromisableEvent(event, emit)
                    .catch()
                    .finally(() => {
                        confirming.value = false;
                        event.target?.parentElement?.dispatchEvent(event.sourceEvent);
                    });
            }
        }

        const onClick = (event: MouseEvent) => {
            if (props.mustConfirm && !confirming.value) {
                confirming.value = true;
                event.stopPropagation();
            } else {
                tryEmitClick(createPromisableEvent(event));
            }
        };

        const onConfirm = (event: MouseEvent) =>
            tryEmitClick(createPromisableEvent(event));


        const onAbort = (event: MouseEvent) => {
            confirming.value = false;
        }

        return {
            t,
            as,
            icon,
            confirming,
            listItem,
            busyIcon,
            itemAttrs,
            ensureTextTranslated,
            onClick,
            onConfirm,
            onAbort

        }

    }
});

</script>

