﻿import type {InjectionKey, Ref} from "vue";
import type {Entities} from "../../types";
import {computed, inject, provide, ref, unref, watch} from "vue";
import {isArray, isDefined} from "../../utils/inspect";
import {getValueFromPropertyPath} from "../../utils/properties";

interface PropertyContext {
    $label?: string;
    $min?: number;
    $max?: number;
    $readonly?: boolean;
    $disabled?: boolean;
    $multiline?: boolean;
}

type PropertyContextDict = Record<string, PropertyContext>;

const INJECTION_KEY: InjectionKey<Ref<PropertyContextDict>> = Symbol("AUNOA_PROPERTY_CONTEXT_DICT");

const initialize = (context: PropertyContextDict, properties: Entities.Model.Property[]) =>
    properties.forEach(p => {
        if (p.type === "Object" && p.properties && isArray(p.properties)) {
            initialize(context[p.name] = {}, p.properties);
        } else if (
            p.label
            || isDefined(p.readonly)
            || isDefined(p.min)
            || isDefined(p.max)
            || p.usage
        ) {
            context[p.name] = {
                $label: p.label,
                $min: p.min,
                $max: p.max,
                $readonly: p.readonly,
                $multiline: p.usage === "MultilineText"
            }
        }
    });

const create = (entityModel?: Entities.Model.Property[]) => {
    const context: PropertyContextDict = {};
    if (entityModel) {
        initialize(context, entityModel);
    }
    //console.log(JSON.stringify(context, undefined, 4));
    return context;
}

export const providePropertyContexts = (entityModel: Ref<Entities.Model.Property[] | undefined>) => {

    const propertyContexts = ref<PropertyContextDict>({});
    watch(entityModel, model => propertyContexts.value = create(model), {immediate: true});

    provide(INJECTION_KEY, propertyContexts);
}

export const usePropertyContext = (property: Ref<string>) => {
    const propertyContexts = inject(INJECTION_KEY, ref({}));
    const propertyContext = ref<PropertyContext>();

    watch(
        [propertyContexts, property],
        ([pc, p]) => propertyContext.value = getValueFromPropertyPath(pc, p),
        {immediate: true}
    );

    //watch(propertyContext, pc=>console.log("######", property.value, unref(pc)),{immediate: true});

    return {
        propertyContext
    }
}