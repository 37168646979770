﻿export const copyToClipboard = (text:string) => {
    const element = document.createElement("textarea");
    element.value = text;
    element.setAttribute("readonly", "");
    element.style.position = "absolute";
    element.style.left = "-9999px";
    document.body.appendChild(element);
    element.focus();
    element.select();
    const result = document.execCommand("copy");
    document.body.removeChild(element);
    return result;
}

