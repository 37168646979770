<template>
    <aunoa-form
        class="bg-form"
        ref="myForm"
        :class="{'form-closing':closing}"
        :entity="workingEntity"
        :entity-model="entityModel"
        :form-model="formModel"
        :lookup-factories="lookupFactories"
        _on-submit="onSubmit"
        v-model:isSubmitted="isSubmitted"
        v-model:hasDirtyChanges="hasDirtyChanges"
    >
        <aunoa-tab-control
            :can-toggle-mode="true"
            class="form-inline inner-border-solid"
            nav-position="start"
            active-style="line"
            nav-class="px-table-cell minw-10r"
            content-class="mh-r16"
            style="min-height: 16rem"
        >
            <aunoa-form-group
                as="aunoa-tab-pane"
                v-for="([id, group]) in Object.entries(formModel)"
                :id="id"
                :link-text="ensureTextTranslated(group.label)"
                class="container-fluid pt-2 pb-4 px-3 ms-0"
                :class="{'maxw-40r':!group.component}"
                row-class="row"
                column-class="col-lg col-12"
                field-class="_mb-3"
                :group-model="group">
                <template v-slot:header>
                    <h5 class="mb-3 font-weight-bold text-primary" v-text="ensureTextTranslated(group.label)" />
                </template>
            </aunoa-form-group>

            <template v-slot:footer>
                <aunoa-nav class="p-0 dashed-top" :grip="true">
                    <template v-if="showEntityChangedResolver">
                        <aunoa-nav-item as="span" class="text-primary" icon="warning" :text="t('Aunoa.Resolve.EntityChanged')" />
                        <aunoa-nav-item :text="t('Aunoa.Command.TakeOther')" @click="onTakeOther" />
                        <aunoa-nav-item :text="t('Aunoa.Command.KeepMine')" @click="onKeepMine" />
                    </template>
                    <aunoa-nav-item
                        v-else
                        type="submit"
                        :text="t('Aunoa.Command.Save')"
                        @click="onSave"
                    />
                    <aunoa-nav-item
                        :text="isSubmitted && !hasDirtyChanges ? t('Aunoa.Command.Close') : t('Aunoa.Command.Cancel')"
                        @click="onClose" />
                    <aunoa-nav-item
                        v-if="false"
                        icon="fad fa-check-square"
                        text="Keep open"
                        item-class="ms-auto" />
                </aunoa-nav>
            </template>
        </aunoa-tab-control>


    </aunoa-form>
</template>



<script lang="ts">

import {defineComponent, ref, toRefs, computed, PropType, watch, watchEffect, isReadonly, onMounted, onUpdated, inject} from "vue";
import {
    AunoaDropdownDivider,
    AunoaDropdownItem,
    AunoaForm,
    AunoaFormGroup,
    AunoaNav,
    AunoaNavItem,
    AunoaTabContent,
    AunoaTabPane,
    AunoaTabControl,
    Forms,
    Entities,
    LookupFactories, CrudService, useAunoaI18n, PromisableEvent
} from "bootstrap-aunoa";

import {useCrudService} from "../implementations/useCrudService";
import {useDataGridI18n} from "../implementations/useDataGridI18n";

const NOT_MODIFIED = 304;
const CONFLICT = 409;

export default defineComponent({
    name: "AunoaEntityEditor",
    components: {
        AunoaDropdownItem,
        AunoaDropdownDivider,
        AunoaForm,
        AunoaFormGroup,
        AunoaNav,
        AunoaNavItem,
        AunoaTabControl,
        AunoaTabContent,
        AunoaTabPane,
    },
    props: {
        entity: {
            type: Object,
            required: true
        },
        entityModel: {
            type: Array as PropType<Entities.Model.Property[]>,
            default: undefined
        },
        formModel: {
            type: Object as PropType<Forms.Model.Form>
        },

        lookupFactories: {
            type: Object as PropType<LookupFactories>,
            default: undefined
        },
        crudService: {
            type: Object as PropType<CrudService>,
            default: undefined
        },
        keepOpenOnUpdate: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        scrollToTop: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update", "close"],
    setup(props, {emit}) {

        const {entity: _entity, crudService} = toRefs(props);
        const service = useCrudService(crudService);

        const myForm = ref<any>();
        const closing = ref(false);


        const _$$ = ref<Entities.DollarDollar>({});
        const workingEntity = computed(() => _$$.value.workingEntity);
        const showEntityChangedResolver = ref(false);

        const {t} = useDataGridI18n();
        const {ensureTextTranslated} = useAunoaI18n();

        const clone = (entity: any) => {
            const json = JSON.stringify(entity);
            const cloned = JSON.parse(json)
            //const cloned = {...entity};
            delete cloned.$$;
            return cloned;
        }

        const hasDirtyChanges = ref(false);
        const isSubmitted = ref(false);


        watch(_entity, entity => {
            if (entity) {
                const $$ = entity.$$ || {};
                $$.key = service.getKey(entity);
                $$.etag = service.getETag(entity);
                $$.workingEntity = $$.workingEntity || clone(entity);
                if (!isReadonly(entity)) {
                    entity.$$ = $$;
                }
                _$$.value = $$;
            } else {
                _$$.value = {};
            }
        }, {immediate: true});


        onMounted(() => {
            if (props.scrollToTop) {
                // todo: Determine fixedHeaderHeight 
                const fixedHeaderHeight = 77 + 1;
                const table = myForm.value.$el.parentElement.parentElement.parentElement.parentElement as HTMLTableElement;
                window.scroll({top: table.offsetTop + fixedHeaderHeight, behavior: "smooth"});
            }
        });

        const closeAnimated = (done: () => void) => {
            closing.value = true;
            const form = myForm.value.$el as HTMLElement;
            form.style.setProperty("--aunoa-height", form.offsetHeight + "px");
            setTimeout(() => {
                closing.value = false;
                done?.();
            }, 350);
        }
        

        const saveAsync = ($$: any) =>
            new Promise<any>((resolve, reject) => {

                const entity = {
                    ..._entity.value,
                    ...workingEntity.value
                }
                $$.submitting = Date.now();
                $$.submitted = undefined;
                $$.submittingFailed = undefined;

                const promise = $$.key
                    ? service.update($$.key, entity, $$.etag)
                    : service.create(entity);

                promise
                    .then(savedEntity => {
                        $$.submitted = Date.now();
                        $$.key = service.getKey(savedEntity);
                        $$.etag = service.getETag(savedEntity);
                        $$.workingEntity = savedEntity;
                        resolve(savedEntity);
                    })
                    .catch(error => {
                        if (error.httpStatus === NOT_MODIFIED) {
                            resolve(entity)
                        } else {
                            $$.submittingFailed = Date.now();
                            reject(error);
                        }
                    });
            })

        const keepMineAsync = ($$: any) =>
            service
                .read($$.key, $$.etag)
                .then(entity => {
                    $$.etag = service.getETag(entity);
                    return saveAsync($$);
                });

        const takeOtherAsync = ($$: any) =>
            service
                .read($$.key, $$.etag)
                .then(otherEntity => {
                    $$.workingEntity = otherEntity;
                    $$.etag = service.getETag(otherEntity);
                    emit("update", {...otherEntity}, "edit");
                });

        const wasEntityModified = (error:any) => error?.httpMethod==="put" && error.httpStatus === CONFLICT;
        
        const onSave = (event: PromisableEvent) =>
            event
                .setPromise(saveAsync(_$$.value), {
                    successDuration: true,
                    failedDuration: true,
                    minDuration: true,
                    dataKind: "Record",
                    failedToast: error => !wasEntityModified(error)
                })
                .then((savedEntity) => {
                    if (props.keepOpenOnUpdate) {
                        emit("update", savedEntity);
                    } else {
                        closeAnimated(() => emit("update", savedEntity));
                    }
                })
                .catch(error => {
                    if (wasEntityModified(error)) {
                        showEntityChangedResolver.value = true;
                    }
                });


        const onKeepMine = (event: PromisableEvent) =>
            event
                .setPromise(keepMineAsync(_$$.value), {failedDuration: true, minDuration: false, failedToast: true})
                .catch()
                .finally(() => showEntityChangedResolver.value = false);

        const onTakeOther = (event: PromisableEvent) =>
            event
                .setPromise(takeOtherAsync(_$$.value), {failedDuration: true, minDuration: true, failedToast: true})
                .catch()
                .finally(() => showEntityChangedResolver.value = false);

        const onClose = () => {
            closing.value = true;
            const form = myForm.value.$el as HTMLElement;
            form.style.setProperty("--aunoa-height", form.offsetHeight + "px");
            setTimeout(() => {
                closing.value = false;
                emit("close", _entity.value);
            }, 350);
        };

        return {
            t,
            ...service,
            ensureTextTranslated,
            hasDirtyChanges,
            isSubmitted,

            myForm,
            closing,
            workingEntity,

            showEntityChangedResolver,
            onSave,
            onClose,
            onTakeOther,
            onKeepMine
        }
    }

});
</script>
