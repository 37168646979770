﻿import {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";
import {useMateBaseUrl} from "./base/useMateBaseUrl";
import axios from "axios";

type UnprocessedDocumentsResult = Record<number, number>;

interface DocumentPart {
    headers: {
        "document-id": string;
        "red-rcp-cpy": string;
    };
    content: string;
}

export interface HtmlDocumentPreview {
    html: string;
    type: "store" | "headoffice" | "customer" | "none";
    variant?: "duplicate" | "gift";
    printed: boolean;
}

const widthCorrections: Record<number, number> = {
    40: 42,
    41: 42,
    44: 46,
    45: 46
}


const convertHtml = (html: string, baseUrl: string) => {

    const regex = /-{20,}|={20,}/ig;

    const barcodeTemplate =  `<div style="height: $4em; background: url(${baseUrl}api/barcode/$2.$3?width=512&amp;height=256&amp;text=$1) no-repeat center bottom; background-size: cover"></div><div class="text-center">$1</div>`;
    

    let max = 0;
    let match = regex.exec(html);
    while (match) {
        max = Math.max(max, match[0].length);
        match = regex.exec(html)
    }
    return html
        .replace(/<code><pre>/g, `<pre style="max-width: calc(${widthCorrections[max] || max || 500}ch + 2px)">`)
        .replace(/<\/pre><\/code>/g, "</pre>")
        .replace(/<!-- Barcode: (\w*);(\w*);(\w*);([\d.]*) -->\s\s/i, barcodeTemplate);
}

export const useDocumentToolsApi = (accessToken: AccessToken) => {

    const {jsonEndpoint, endpoint, authorizationHeader} = useMateApi(accessToken)
    const jsonApi = jsonEndpoint("/api");
    const api = endpoint("/api");

    const {baseUrl} = useMateBaseUrl();

    const getUnprocessedDocuments = () =>
        jsonApi.get<UnprocessedDocumentsResult>(undefined, {url: `/documents/unprocessed`});

    const getDocumentsThroughput = (tenantOid: number, month: Date) =>
        jsonApi.get({referenceMonth: month.toISOString(), tenantOid: tenantOid}, {url: `/documents/throughput`});

    const getDocumentsAsHtml = (documentUid: string) =>
        api.get<DocumentPart[]>(undefined, {
            url: `/download/receipt/${documentUid}/${documentUid}.html?notFoundFallback=true`,
            multiPart: {type: "mixed", detailed: true},
            headers: {
                "Accept": "multipart/mixed"
            }
        }).then(list => list.map(i => {
            const match = /(\w*)(?:-(\w*))?; (?:(not) )?printed/.exec(i.headers["red-rcp-cpy"]);
            return <HtmlDocumentPreview>{
                html: convertHtml(i.content, baseUrl),
                type: match?.[1],
                variant: match?.[2],
                printed: !match?.[3]
            };
        }));

    const getDocumentAttachmentUrl = (documentUid: string, oid: number, id: string) =>
        `${baseUrl}api/download/receipt-attachment/${documentUid}/${oid}/${id}`;

    const getDocumentAttachmentBlob = (documentUid: string, oid: number, id: string) =>
        axios.get(getDocumentAttachmentUrl(documentUid, oid, id), {
            headers: {...authorizationHeader()},
            responseType: "blob"
        }).then(response => response.data);

    const getDownloadPdfUrl = (entity: any, name?: string) =>
        `/api/download/receipt/${entity.Uid}/${name || entity.Id}`;

    const getDownloadXmlUrl = (entity: any, name?: string) =>
        `/api/download/receipt/${entity.Uid}/${name || entity.Id}`;

    const getDownloadZipUrl = (entity: any, name?: string) =>
        `/api/download/receipt/${entity.Uid}/${name || entity.Id}`;

    return {
        getUnprocessedDocuments,
        getDocumentsThroughput,
        getDocumentsAsHtml,
        getDocumentAttachmentUrl,
        getDocumentAttachmentBlob,
        getDownloadPdfUrl,
        getDownloadXmlUrl,
        getDownloadZipUrl

    }
};